import React from 'react';
import Tick from '../../assets/images/icons/filled-checkbox.svg';
import PropTypes from 'prop-types';

function SelectedCheckbox(props) {
  const labelClasses = props.labelClasses ? props.labelClasses : '';

  return (
    <label>
      <input
        type="checkbox"
        onChange={props.onDeselect}
        defaultChecked={true}
        className='hidden'
      />
      <div className='flex gap-x-2 items-start inline cursor-pointer'>
        <Tick className='fill-grey-100 flex-shrink-0' />
        <div className={`max-w-[240px] overscroll-x-contain overflow-x-auto text-grey-700 ${labelClasses}`}>
          {props.label}
        </div>
      </div>
    </label>
  );
}

SelectedCheckbox.propTypes = {
  value: PropTypes.object.isRequired,
  onDeselect: PropTypes.func.isRequired,
  label: PropTypes.string,
  labelClasses: PropTypes.string
};

export default SelectedCheckbox;
