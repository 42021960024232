import { Component } from 'react';
import update from 'immutability-helper';
import { keys, isEqual, some, toArray } from 'lodash';
import PropTypes from 'prop-types';

// This class encapsulates the common filter logic in one place.
// It is currently used as the base for all FilterSidebars and the
// KeyDecisionMakerModuleFilters.
// Note: I know this makes our React even more based on Inheritance
//       instead of using Composition, but with the limited time
//       available this was the best option.
//       (Jalada made me do it.🙃)
export default class CommonFilterLogic extends Component {
  constructor(props) {
    super(props);

    const { filters } = props;

    keys(this.constructor.defaultFilters).forEach((key) => {
      if (!filters[key]) {
        filters[key] = this.constructor.defaultFilters[key];
      }
    });

    this.state = {
      filters,
      openFilterGroups: {},
      submitting: false
    };
  }

  toggleOpen(filterGroupKey, open) {
    const updateObj = {
      openFilterGroups: { [filterGroupKey]: { $set: open } }
    };
    this.setState(update(this.state, updateObj));
  }

  // Returns a JSON string representing the filters in a filter tree. It
  // does this by:
  //
  // 1. Getting the subclass to generate the submittable filters in an
  //    {[filterName]: inputs} format.
  // 2. Filtering away any default filters (e.g. ones that the user has not
  //    changed).
  // 3. Adding all those filters to the array of filter elements.
  // 4. Checking whether we need to construct a special OR node for the
  //    supplier name AND/OR category filter (on spend). One day this will
  //    be a bit more generic.
  // 5. Converting it to a JSON string.
  submittableFilterTree() {
    const filters = this.toSubmittableFilters();

    const newFilters = {
      operation: 'and',
      elements: []
    };

    // Filter away any default filters & push into array of filters
    keys(filters).forEach(key => {
      if (!isEqual(filters[key], this.constructor.defaultFilters[key])) {
        newFilters.elements.push({ name: key, input: filters[key] });
      }
    });

    // If the user has done no filtering, return an empty object. This is
    // to keep consistency between our old code, rather than an empty search
    // being an object with an operation & empty array of elements.
    if (newFilters.elements.length === 0) {
      return '{}';
    }

    // For now our 'and/or' is built manually for this one case. We're going
    // to tidy this up one day, maybe when we add the next and/or filter.
    if (this.useOrFilter(filters)) {
      const combinatorialFilter = {
        operation: 'or',
        // Strictly speaking we shouldn't need the name of this filter because
        // this is just a node of our tree. But IIRC we have some workaround
        // transformation code that turns it into the old structure that relies
        // on spotting this. Hopefully we can clean it up later.
        name: 'supplierNameAndOrCategory',
        elements: this.combinedSupplierFilters.map((filter) => {
          return { name: filter, input: filters[filter] };
        })
      };

      // Remove the supplier filters from the top level.
      newFilters.elements = newFilters.elements.filter(f => {
        return !this.combinedSupplierFilters.includes(f.name);
      });

      // And push the new filter onto the list of elements
      newFilters.elements.push(combinatorialFilter);
    }

    // Make sure we never have this pseudo-filter in our filters. This can
    // happen if the user chooses 'or' for the supplier filter behaviour
    // but doesn't put anything in either filter.
    newFilters.elements = newFilters.elements.filter(f => {
      return !(f.name === 'supplierNameAndOrCategory' && !f.elements);
    });

    return JSON.stringify(newFilters);
  }

  useOrFilter(filters) {
    return (
      filters.supplierNameAndOrCategory === 'or' &&
      this.combinedSupplierFilters.every(filter => (
        typeof filters[filter] !== 'undefined' &&
          !isEqual(filters[filter], this.constructor.defaultFilters[filter])
      ))
    );
  }

  handleFilterChange(filter, updateObj) {
    const newState = update(this.state, {
      filters: { [filter]: updateObj },
      changed: { $set: true }
    });
    this.setState(newState);
  }

  // Returns true if any of the filters named differ from the default when
  // first rendered (e.g. the ones supplied from a previous search)
  any() {
    return some(toArray(arguments), (filter) => {
      return !isEqual(this.props.filters[filter], this.constructor.defaultFilters[filter]);
    });
  }

  // Reveals a overlay on the data table.
  revealOverlay() {
    this.props.overlay.classList.remove('hidden');
    this.props.overlay.classList.add('absolute');
  }

  submit(e) {
    e.preventDefault();
    // Set the state to submitting so that we can use this to render a loading
    // spinner and translucent overlay.
    this.setState({ submitting: !this.state.submitting });
    this.revealOverlay();
    this.form.submit();
  }

  render() {
    return this.renderComponent();
  }
}

CommonFilterLogic.propTypes = {
  filters: PropTypes.object,
  overlay: PropTypes.object
};
